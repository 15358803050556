
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import FormInput from '@/components/FormInput/FormInput.vue'
import Reconciliation from '@/models/Reconciliation'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ReconciliationClient from '@/models/ReconciliationClient'
import { clone, uniqueId, groupBy as _groupBy } from 'lodash'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import Invoice from '@/models/Invoice'
import DataTable from '@/components/DataTable/index.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { PaginationOptionsAll } from '@/models/interface/Common'
import moment from 'moment'
import WebMessage from '@/models/WebMessage'
import clientGroupFields from './invoice-batch-client-fields'
import InvoiceBatchSubTable from './Components/InvoiceBatchSubTable.vue'
import InternalSearch from './Components/InternalSearch.vue'
import InvoicePrintView from './Components/InvoicePrintView.vue'
import MediaOceanPrintView from './Components/MediaOceanPrintView.vue'
import MediaPlanView from '../Sales/MediaPlan/MediaPlanView.vue'
import {
  invoice_type_options,
  invoice_group_options,
  invoice_detail_options,
  query_settings,
} from './options'

@Component({
  components: {
    Widget,
    FormInput,
    DataTable,
    FooterNav,
    IconAction,
    InvoiceBatchSubTable,
    SelectPicker,
    SearchInput,
    DatePicker,
    InternalSearch,
    InvoicePrintView,
    MediaPlanView,
    MediaOceanPrintView,
  },
})
export default class InvoiceBatchCreate extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Ref() readonly subTable!: any

  public uuid: string = uniqueId()

  public temp_invoice: Invoice[] = []

  public busy: boolean = true

  public loading: boolean = false

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public isAllSelected: boolean = false

  public page_size: any = 1

  public page: number = 1

  public records: number = 0

  public ready: boolean = false

  public selected: string[] = []

  public fieldFilters: any = {}

  public query: string[] = []

  public fields: Array<any> = []

  public checked_group: string[] = []

  public checked_control: any = []

  public reconciliation_clients: any = []

  public restore_reconciliation_clients: any = []

  public showAllDetails: boolean = false

  public generating: boolean = false

  public show_details: boolean = false

  public invoice_index: number = 0

  public reconciliation_row_index: number = 0

  public open_modal_after_page_load: boolean = false

  public is_saving: boolean = false

  public invoice_preview_mode = 'invoice'

  @Watch('isAllSelected')
  public onSelectedChange(val: any) {
    if (!val) {
      this.resetSelection()
    } else {
      this.reconciliation_clients.forEach((group: any) => {
        this.checked_group.push(group.uuid)
        Vue.set(
          this.checked_control,
          group.uuid,
          group.items.map((item: Reconciliation) => item.id),
        )
      })
    }
  }

  public get invoice_preview_options() {
    return this.temp_invoice.map((invoice: Invoice, index: number) => ({
      value: index,
      text: invoice.name,
    }))
  }

  // Default table pagination options
  public get pagination_options() {
    return PaginationOptionsAll
  }

  public get options() {
    return {
      invoice_type_options,
      invoice_group_options,
      invoice_detail_options,
      query_settings,
    }
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public toggleView() {
    if (this.invoice_preview_mode === 'invoice') this.invoice_preview_mode = 'schedule'
    else this.invoice_preview_mode = 'invoice'
  }

  // Not 100% done must try with other types beside string
  public searchable_rules = {
    advertiser: (item: any) => item.advertiser.name,
    agency: (item: any) => item.agency.name,
    type: (item: any) => item.type,
  }

  /**
   * Capture the paste event and get the data
   */
  private registerEvent() {
    document.addEventListener('keydown', this.onKeyDown)
  }

  /**
   * Clear paste event to prevent triggering the event outside the page
   */
  private unregisterEvent() {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  public mounted() {
    this.registerEvent()
    this.fields = clientGroupFields
    this.busy = false

    this.$root.$on('bv::modal::hidden', (bvEvent: any, modalId: any) => {
      if (modalId === 'invoice-print') {
        this.show_details = false
      }
    })
  }

  /**
   * Unregister event listeners
   */
  public beforeDestroy() {
    this.unregisterEvent()
  }

  private onKeyDown(e: KeyboardEvent) {
    if (this.loading) return

    if (!this.show_details) {
      return
    }
    if (e.shiftKey && e.key === 'ArrowLeft') {
      e.preventDefault()
      this.invoiceModalNavTo('prev')
      return
    }

    if (e.shiftKey && e.key === 'ArrowRight') {
      e.preventDefault()
      this.invoiceModalNavTo()
    }
  }

  /**
   *
   * This function is a trigger for the updated row item "Group by"
   *
   * If the type is media_ocean locks the property group_by to invoice_per_media_plan
   *
   * It will search in invoice_group_options for the Media Ocean default option
   *
   */
  public updateItemGroupBy(item: any, selected: any) {
    if (selected.value === 'media_ocean' || selected.value === 'strata') {
      item.group_by = this.options.invoice_group_options.find(
        (op: any) => op.value === 'invoice_per_media_plan',
      )?.value
    }
  }

  /**
   * Hide All sub items
   */
  private showAll() {
    this.showAllDetails = true
    this.toggleDetails('all', 'show')
  }

  /**
   * Show all sub items
   */
  private hideAll() {
    this.showAllDetails = false
    this.toggleDetails('all', 'hide')
  }

  private toggleDetails(target: string, action: string = 'toggle') {
    if (target === 'all') {
      this.reconciliation_clients.forEach(group => {
        group._showItems = action === 'show' ? true : action === 'hide' ? false : !group._showItems
      })

      return
    }
    let record = this.reconciliation_clients.find((item: any) => item.uuid === target)
    if (record) {
      record._showItems = action === 'show' ? true : action === 'hide' ? false : !record._showItems
    }
  }

  public resetModalNav() {
    this.invoice_index = 0
    this.reconciliation_row_index = 0
  }

  public reconciliationPaginateClients(context: any) {
    this.loading = true
    this.busy = false
    let page = this.page_size === 'all' ? this.page_size : context.perPage
    return Reconciliation.batchPaginate({
      page_size: page,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.query,
    }).then(result => {
      this.records = result.records
      if (this.records == 0 && this.is_saving) {
        this.$router.push({ name: 'Invoices' })
        return []
      }
      this.is_saving = false
      this.reconciliation_clients = result.data.map((clientGroup, index) => {
        if (clientGroup.type === 'default') {
          if (clientGroup.client?.billing_info.hasOwnProperty('invoice_group')) {
            clientGroup.invoice_group = clientGroup.client?.billing_info.invoice_group
          }
          if (clientGroup.client?.billing_info.hasOwnProperty('invoice_detail')) {
            clientGroup.group_by = clientGroup.client?.billing_info.invoice_detail
          }
        }

        // Calculate invoice count per advertiser
        clientGroup.invoice_count.advertiser = clientGroup.items.reduce(
          (total, item) => {
            if (item.advertiser_id && !total.set.includes(item.advertiser_id)) {
              total.set.push(item.advertiser_id)
              total.count++
            }
            return total
          },
          { set: [] as string[], count: 0 },
        ).count

        // Calculate invoice count per media plan
        clientGroup.invoice_count.media_plan = clientGroup.items.reduce(
          (total, item) => {
            if (item.media_plan_id && !total.set.includes(item.media_plan_id)) {
              total.set.push(item.media_plan_id)
              total.count++
            }
            return total
          },
          { set: [] as string[], count: 0 },
        ).count
        return clientGroup
      })

      // check group modes

      this.restore_reconciliation_clients = clone(this.reconciliation_clients)

      this.isAllSelected = false
      this.hideAll()
      setTimeout(() => {
        this.loading = false
        // this.showAll()
        this.isAllSelected = true

        if (this.open_modal_after_page_load) {
          this.loading = true
          this.resetModalNav()
          this.viewDetails(0)
        }
      }, 1000)
      return this.reconciliation_clients
    })
  }

  /**
   * Triggered on group check box change, it updates the sub items check state
   */
  private toogleItems(group: ReconciliationClient) {
    // Check if group is checked
    const isChecked = this.checked_group.includes(group.uuid)
    // Vue Set is Required to ensure that the UI is updated properly on array change
    Vue.set(
      this.checked_control,
      group.uuid,
      isChecked ? group.items.map((item: Reconciliation) => item.id) : [],
    )
  }

  public get indeterminate(): boolean {
    return (
      this.checked_group.length > 0
      && this.checked_group.length < this.reconciliation_clients.length
    )
  }

  /**
   * Check if a group has at least one checked item but not all
   */
  public isGroupIndeterminate(group: ReconciliationClient) {
    return (
      this.checked_control[group.uuid]
      && this.checked_control[group.uuid].length > 0
      && this.checked_control[group.uuid].length < group.items.length
    )
  }

  public get canSave() {
    if (this.checked_group && !this.checked_group.length) return true
    return false
  }

  public openModal(modal_id: string, time: number = 100): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', modal_id)
        resolve(true)
      }, time)
    })
  }

  public invoiceModalNavTo(to: any = null) {
    let modal_body = document.getElementsByClassName('modal-body')

    let lastTableIndex = this.reconciliation_clients.length - 1

    let scrollTo = () => {
      if (modal_body[0]) modal_body[0].scroll({ top: 0, behavior: 'smooth' })
    }

    // go next invoice
    if (!to) {
      // === Condition for next Invoice
      // scroll to top

      let lastIndex = this.temp_invoice.length - 1

      if (this.temp_invoice.length > 1) {
        // check current index
        if (this.invoice_index < lastIndex) {
          scrollTo()
          setTimeout(() => {
            this.invoice_index++
          }, 100)
        }
        if (this.invoice_index === lastIndex) {
          // next table row
          this.invoice_index = 0
          this.reconciliation_row_index++
          this.temp_invoice = this.buildInvoices(this.reconciliation_row_index, true)
        }
      } else {
        // next table row
        if (this.reconciliation_row_index < lastTableIndex) {
          this.reconciliation_row_index++
          this.temp_invoice = this.buildInvoices(this.reconciliation_row_index, true)
        }

        if (this.reconciliation_row_index === lastTableIndex) {
          // check if there is next page
          if (this.page * this.page_size < this.records) {
            // close modal
            this.$bvModal.hide('invoice-print')
            this.reconciliation_row_index = 0
            this.invoice_index = 0
            // api request next page
            this.page++
            this.open_modal_after_page_load = true
            setTimeout(() => {
              this.dataTable.refresh()
            }, 500)
          }
        }
      }
    } else {
      // go to prev invoice or row in the table

      if (this.invoice_index - 1 >= 0) {
        scrollTo()
        setTimeout(() => this.invoice_index--, 300)
      }

      if (this.invoice_index - 1 < 0) {
        // prev row or prev page

        // check prev row
        let prevRow = this.reconciliation_row_index - 1

        if (prevRow >= 0) {
          this.reconciliation_row_index--
          this.invoice_index = 0
          this.buildInvoices(this.reconciliation_row_index, true)
        }

        if (prevRow < 0) {
          this.invoice_index = 0
          this.reconciliation_row_index = 0

          // request api prev page
          if (this.page > 1) {
            this.page--
            this.open_modal_after_page_load = true
            setTimeout(() => {
              this.dataTable.refresh()
            }, 500)
          }
          // else first row in the table, but in page 1
        }
      }
    }
  }

  public viewDetails(index: number) {
    // track invoice index inside of reconciliation_row
    this.invoice_index = 0

    this.reconciliation_row_index = index

    this.temp_invoice = this.buildInvoices(index, true)

    this.openModal('invoice-print', 300)

    if (this.open_modal_after_page_load) {
      setTimeout(() => {
        this.show_details = true
        this.open_modal_after_page_load = false
        this.loading = false
      }, 500)
    } else {
      this.show_details = true
    }
  }

  public buildInvoices(index: any = null, preview: boolean = false) {
    let filtred_clients: any = null

    if (index !== null) {
      let client = this.reconciliation_clients[index]
      if (this.checked_group.includes(client.uuid)) {
        client.items = client.items.filter((item: any) =>
          this.checked_control[client.uuid].includes(item.id))
      }
      filtred_clients = [client]
    } else {
      filtred_clients = this.reconciliation_clients.filter((row: ReconciliationClient) => {
        if (this.checked_group.includes(row.uuid)) {
          row.items = row.items.filter((item: any) =>
            this.checked_control[row.uuid].includes(item.id))
          return true
        }
        return false
      })
    }

    let clients = Invoice.setGroups(filtred_clients)

    return clients.map((c: any) => Invoice.buildInvoice(c, preview))
  }

  public saveBatch(request_approval: boolean = false, send_reminder: boolean = false) {
    if (!this.is_quickbooks_connected) {
      WebMessage.error('Please connect your Quickbookks account before creating an invoice', [
        {
          text: 'Connect Now!',
          action: (toast: any) => {
            this.$router.push({ name: 'Account' })
            WebMessage.hide(toast.id)
          },
        },
      ])
      return
    }
    this.loading = true
    this.busy = true
    this.generating = true
    this.is_saving = true

    let invoices = this.buildInvoices()

    Invoice.batchCreate(invoices, request_approval, send_reminder).then(e => {
      WebMessage.success(
        'Batch invoice processing complete! It might still take a few minutes for Quickbooks update its records.',
      )

      this.loading = false
      this.busy = false
      this.generating = false
      setTimeout(() => {
        this.refreshTable()
      }, 500)
    })
  }

  public resetSelection() {
    Vue.set(this, 'checked_group', [])
    Vue.set(this, 'checked_control', [])
  }

  public refreshTable() {
    this.hideAll()
    this.dataTable.refresh()
    setTimeout(() => {
      this.isAllSelected = true
    }, 1000)
  }
}
