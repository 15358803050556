
import ViewModel from '@/models/ViewModel'
import { Component, Prop, Watch } from 'vue-property-decorator'
import Multiselect from 'vue-multiselect'
import { clone } from 'lodash'

@Component({
  components: {
    Multiselect,
  },
})
export default class InternalSearch extends ViewModel {
  @Prop()
  public data!: any

  @Watch('data')
  public onDataChange(val: any) {
    if (val && val.length) {
      this.generateOptions()
    }
  }

  @Prop()
  public searchable!: any

  @Prop()
  public value!: any

  public option_list: Array<any> = []

  public local_value: any = []

  @Watch('local_value')
  public onLocalValueChange(val: any) {
    if (!val.length) {
      this.restoreFromBackup()
    } else {
      this.filterData()
    }
  }

  public restore: any = []

  public createBackup() {
    if (!this.restore.length) this.restore = clone(this.data)
  }

  public restoreFromBackup() {
    this.$emit('update:data', this.restore)
  }

  public search(str: string, val: string) {
    return str.toLocaleLowerCase().search(val) !== -1
  }

  public generateOptions() {
    this.createBackup()
    let all: any = []
    let keys = Object.keys(this.searchable)
    this.data.forEach((item: any) => {
      keys.forEach(key => {
        let val = this.searchable[key](item)
        all.push(`${key}:${val}`)
      })
    })
    all = [...new Set(all)]
    this.option_list = all.sort()
  }

  public filterData() {
    let acc: Array<any> = []

    this.local_value.forEach((search_opt: string) => {
      let entries = search_opt.split(':')
      let key = entries[0].toLocaleLowerCase()
      let val = entries[1].toLocaleLowerCase()

      let found = this.data.filter((item: any) => this.search(this.searchable[key](item), val))

      acc = [...acc, ...clone(found)]
    })
    this.$emit('update:data', acc)
  }

  mounted() {}
}
