export default [
  {
    key: 'index',
    label: '#',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },
  {
    key: 'name',
    label: 'Item',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'product',
    label: 'Product',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'isci_code',
    label: 'ISCI Code',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'isci_duration',
    label: 'ISCI Duration',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'flight_dates',
    label: 'Flight Dates',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'date',
    thClass: 'align-middle text-center',
  },
  {
    key: 'service_month',
    label: 'Month of Service',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'booked',
    label: 'Booked',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    type: 'separator',
    thClass: 'align-middle text-center',
  },
  {
    key: 'delivered',
    label: 'Delivered',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    type: 'separator',
    thClass: 'align-middle text-center',
  },
  {
    key: 'system_impressions',
    label: 'Delivered',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    type: 'separator',
    thClass: 'align-middle text-center',
  },
  {
    key: 'reconciliated_impressions',
    label: '3rd Party',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    type: 'separator',
    thClass: 'align-middle text-center',
  },
  {
    key: 'billable',
    label: 'Billable',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'separator',
    thClass: 'align-middle text-center',
  },
  {
    key: 'gross_rate',
    label: 'Gross Rate',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center',
  },
  {
    key: 'gross_total',
    label: 'Gross Total',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center',
  },
  {
    key: 'net_rate',
    label: 'Net Rate',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center',
  },
  {
    key: 'net_total',
    label: 'Net Total',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center',
  },
]
