
import { Component, Vue, Prop } from 'vue-property-decorator'
import ModelFileUploader from '@/components/ModelFile/ModelFileUploader.vue'
import ViewModel from '@/models/ViewModel'
import MediaPlan from '@/models/MediaPlan'
import DataTable from '@/components/DataTable/index.vue'

import moment from 'moment'
import { capitalize } from 'lodash'
import schedule_table_fields from '../schedule-table-fields'
import broadcast_table_fields from '../broadcast-table-fields'

@Component({
  components: {
    ModelFileUploader,
    DataTable,
  },
})
export default class MediaOceanPrintView extends ViewModel {
  @Prop()
  public invoice!: any

  @Prop()
  public custom_items!: any

  @Prop({ default: () => [] })
  public errors!: string[]

  @Prop({ default: () => [] })
  public warnings!: string[]

  public type = 'cash'

  public schedules: any = []

  public get isMediaOcean() {
    return this.invoice.type === 'media_ocean'
  }

  public get invoice_number() {
    if (this.type === 'trade') {
      if (this.invoice.invoice_number_revised.includes('-')) {
        return this.invoice.invoice_number_revised.replace(/-/g, 'T-')
      }
      return `${this.invoice.invoice_number_revised}T`
    }
    return this.invoice.invoice_number_revised
  }

  public get filtered_items() {
    return this.invoice.items.filter((item: any) => item.metadata.order_type === this.type)
  }

  public get total_spots() {
    return this.filtered_items.reduce((acc, item) => acc + parseInt(item.metadata.spots), 0)
  }

  public created() {
    let type = this.$route.query.type

    if (type && typeof type === 'string') {
      this.type = type
    }
  }

  public errorClass(...targets: string[]): string {
    for (const target of targets) {
      if (this.errors.includes(target)) {
        return 'text-danger font-weight-bolder'
      }

      if (this.warnings.includes(target)) {
        return 'text-warning font-weight-bolder'
      }
    }

    return ''
  }

  /**
   * TODO wait for more information
   *
   * Use at the scheduled table
   */
  public get schedule_fields() {
    return schedule_table_fields.filter(s => s.show)
  }

  public get broadcast_fields() {
    return broadcast_table_fields.filter(s => s.show)
  }

  public clearName(name: string) {
    return name.replaceAll('_', ' ')
  }

  public itemList(media_plan_id: any) {
    return this.invoice.items.filter((e: any) => this.getMediaPlanItems(media_plan_id, e.name))
  }

  public getMediaPlanItems(media_plan_id: string, name: string) {
    let splited = name.split(':')
    let plan = splited[0]
    let found = this.invoice.media_plans.find((mp: any) => mp.id === media_plan_id)
    return found.name === plan
  }

  public getMediaPlan() {
    let media_plan = MediaPlan.toObject(this.invoice.reconciliations[0])
    this.schedules = media_plan.formSchedule
  }

  public changeType(type: string) {
    this.type = type
  }
}
