export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    class: 'text-center align-middle text-uppercase',
    show: true,
    type: 'checkbox',
  },
  {
    key: 'code',
    label: 'Item Code',
    sortable: false,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'media_plan',
    label: 'Media Plan',
    sortable: false,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'media_plan_item',
    label: 'Media Plan Item',
    sortable: false,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'media_plan_item',
    label: 'Media Plan Item',
    sortable: false,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'billing_month', // passar o ano inves do mes
    label: 'Billing Month',
    type: 'date',
    sortable: true,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'media_plan_net_total',
    label: 'Line Item Net Total',
    sortable: false,
    class: 'text-center align-middle text-uppercase',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center',
  },
  {
    key: 'media_plan_gross_total',
    label: 'Line Item Gross Total',
    sortable: false,
    class: 'text-center align-middle text-uppercase',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center',
  },

  {
    key: 'gross_rate',
    label: 'Gross Rate',
    type: 'currency',
    sortable: true,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'net_rate',
    label: 'Net Rate',
    type: 'currency',
    sortable: true,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'gross_total',
    label: 'Gross Total',
    type: 'currency',
    sortable: true,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'net_total',
    label: 'Net total',
    type: 'currency',
    sortable: true,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
]
