
import ViewModel from '@/models/ViewModel'

import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'

import Widget from '@/components/Widget/Widget.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import { clone, uniqueId } from 'lodash'
import subtableFields from '../invoice-batch-subtable-fields'

@Component({
  components: {
    Widget,
    FormInput,
    DataTable,
  },
})
export default class InvoiceBatchSubTable extends ViewModel {
  @Prop()
  public value!: any

  @Prop({ default: () => [] })
  public readonly checked!: Array<string>

  public uuid: string = uniqueId()

  public loading: boolean = false

  public records: number = 0

  public query: string[] = []

  public fields: Array<any> = []

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  // Returns checked prop to prevent updating prop
  public get local_checked(): Array<string> {
    return this.checked
  }

  // Update parent checked prop
  public set local_checked(value: Array<string>) {
    this.$emit('update:checked', value)
  }

  mounted() {
    this.fields = subtableFields
  }

  get item_list() {
    return this.value.items
  }

  public getItemCode(item: any) {
    return `${String(item.media_plan.number).padStart(4, '0')}-${String(
      item.media_plan_item.number,
    ).padStart(4, '0')}`
  }
}
