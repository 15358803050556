export default [
  {
    key: 'days',
    label: 'Days',
    sortable: false,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'times_ordered',
    label: 'Times Ordered',
    sortable: false,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'line',
    label: 'Line',
    sortable: false,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'rate',
    label: 'Rate',
    sortable: false,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
  {
    key: 'month',
    label: 'No/Month',
    sortable: false,
    class: 'text-center align-middle text-uppercase',
    show: true,
  },
]
