
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class MediaPlanHeaderProperty extends Vue {
  @Prop()
  public label!: string;

  @Prop()
  public value!: string;
}
