
import { Component, Prop } from 'vue-property-decorator'
import MediaPlan from '@/models/MediaPlan'
import IconAction from '@/components/IconAction/IconAction.vue'
import ViewModel from '@/models/ViewModel'
import DataTable from '@/components/DataTable/index.vue'
import moment from 'moment'
import MediaPlanItem from '@/models/MediaPlanItem'
import dmas from '@/data/dmas'
import CustomIcon from '@/components/CustomIcon/CustomIcon.vue'
import MediaPlanHeaderProperty from './MediaPlanHeaderProperty.vue'

@Component({
  components: {
    MediaPlanHeaderProperty,
    IconAction,
    DataTable,
    CustomIcon,
  },
})
export default class MediaPlanPrintView extends ViewModel {
  @Prop()
  public media_plan!: MediaPlan

  public page_navigator: any = false

  public previewing_type: any = null

  public loading_pdf: boolean = false

  @Prop({ default: false })
  public showDelivery!: boolean

  @Prop({ default: () => [] })
  public showOnly!: string[]

  @Prop({ default: () => [] })
  public highlightItems!: string[]

  @Prop({ default: () => [] })
  public dangerItems!: string[]

  @Prop({ default: () => false })
  public showDetails!: boolean

  public get client_name() {
    if (this.media_plan?.agency_id === this.rootCompanyId) {
      return this.media_plan?.advertiser?.name
    }

    return this.media_plan?.agency?.name
  }

  public get localShowDetails() {
    return this.showDetails
  }

  public set localShowDetails(value: boolean) {
    this.$emit('update:showDetails', value)
  }

  public shouldHightlight(id: string) {
    return this.highlightItems.includes(id)
  }

  public shouldShow(id: string) {
    return this.showOnly.includes(id)
  }

  public shouldShowDanger(id: string) {
    return this.dangerItems.includes(id)
  }

  public get filteredItems() {
    if (this.showOnly.length == 0) return this.media_plan.line_items
    return this.media_plan.line_items.filter((item: any) => this.shouldShow(item.id))
  }

  public get fields() {
    const forceAll = false
    const fields: any = [
      {
        key: 'number',
        label: '#',
        sortable: false,
        show: true,
        tdClass: 'align-middle text-center',
      },
      {
        key: 'name',
        label: 'Name',
        sortable: false,
        show: true,
        tdClass: 'align-middle',
      },
      {
        key: 'media_package',
        label: 'Media Package',
        sortable: false,
        show: forceAll || this.media_plan.metadata.view_columns.includes('media_package'),
        tdClass: 'align-middle',
        type: 'object',
        object: {
          key: 'media_package',
          property: 'name',
        },
      },
      {
        key: 'start_at',
        label: 'Start Date',
        sortable: false,
        show: true,
        tdClass: 'align-middle',
        type: 'date',
      },
      {
        key: 'end_at',
        label: 'End Date',
        sortable: false,
        show: true,
        tdClass: 'align-middle',
        type: 'date',
      },
      {
        key: 'model',
        label: 'Model',
        sortable: false,
        show: forceAll || this.media_plan.metadata.view_columns.includes('model'),
        tdClass: 'align-middle',
        type: 'uppercase',
      },
      {
        key: 'max_avails',
        label: 'Max Avails',
        sortable: false,
        show: forceAll || this.media_plan.metadata.view_columns.includes('max_avails'),
        tdClass: 'align-middle',
        type: 'abbreviate',
      },
      {
        key: 'grps',
        label: 'GRPs',
        sortable: false,
        show: forceAll || this.media_plan.metadata.view_columns.includes('grps'),
        tdClass: 'align-middle',
      },
      {
        key: 'impressions',
        label: 'Impressions',
        sortable: false,
        show: true,
      },
      {
        key: 'delivery',
        label: 'Delivery',
        sortable: false,
        show: this.showDelivery !== false,
        tdClass: 'text-center align-middle text-capitalize',
        thClass: 'align-middle text-center',
        type: 'currency',
      },
      {
        key: 'gross_rate',
        label: 'Gross Rate',
        sortable: false,
        show: forceAll || this.media_plan.metadata.view_columns.includes('gross_rate') || this.media_plan.isLinear,
        tdClass: 'align-middle',
        type: 'currency',
      },
      {
        key: 'net_rate',
        label: 'Net Rate',
        sortable: false,
        show: forceAll || this.media_plan.metadata.view_columns.includes('net_rate') || this.media_plan.isLinear,
        tdClass: 'align-middle',
        type: 'currency',
      },
      {
        key: 'gross_cost',
        label: 'Gross Cost',
        sortable: false,
        show: forceAll || this.media_plan.metadata.view_columns.includes('gross_cost') || this.media_plan.isLinear,
        tdClass: 'align-middle',
        type: 'currency',
      },
      {
        key: 'net_cost',
        label: 'Net Cost',
        sortable: false,
        show: forceAll || this.media_plan.metadata.view_columns.includes('net_cost') || this.media_plan.isLinear,
        tdClass: 'align-middle',
        type: 'currency',
      },
      {
        key: 'notes',
        label: 'Notes',
        sortable: false,
        tdClass: 'align-middle',
      },
    ]

    return fields.filter((field: any) => field.show !== false)
  }

  public lineItemSpotIndex(line_item: MediaPlanItem, index: number): number {
    let pointer = moment(this.media_plan.start_at).startOf('week').add(index, 'weeks').add(1, 'day')
    let start = moment(line_item.start_at).startOf('week').add(1, 'day')

    if (index > 0) {
      start.add(index, 'weeks').startOf('week').add(1, 'day')
    }

    let ret: number = 1 + index

    while (pointer.isBefore(start)) {
      pointer.add(1, 'weeks')
      ret++
    }

    return ret
  }

  public getWeekDates(start_at: string, week: number): string {
    const start = moment(start_at).add(week, 'weeks')
    return `${start.format('MM/DD/YYYY')} ~ ${start
      .endOf('week')
      .add(1, 'days')
      .format('MM/DD/YYYY')}`
  }

  public getTargeting(line_item: MediaPlanItem, type: string) {
    const include = line_item.metadata.targetting.include[type]
    const exclude = line_item.metadata.targetting.exclude[type]
    const limit = 5

    let ret = ''

    if (include.length + exclude.length === 0) {
      ret = '-'
    } else if (include.length + exclude.length <= limit) {
      if (include.length > 0) {
        ret += include
          .map((i: any) => {
            if (type === 'dmas') {
              return `<span class="text-success"><b>+</b> ${
                dmas.find(d => d.id === i)?.name
              } (${i})</span>`
            }
            return `<span class="text-success"><b>+</b> ${i}</span>`
          })
          .join(', ')
      }

      if (ret && exclude.length > 0) ret += ', '
      if (exclude.length > 0) {
        ret += exclude
          .map((i: any) => {
            if (type === 'dmas') {
              return `<span class="text-danger"><b>-</b> ${
                dmas.find(d => d.id === i)?.name
              } (${i})</span>`
            }
            return `<span class="text-danger"><b>-</b> ${i}</span>`
          })
          .join(', ')
      }
    } else {
      if (include.length > 0) ret += `${include.length} included`

      if (ret && exclude.length > 0) ret += ', '

      if (exclude.length > 0) ret += `${exclude.length} excluded`
    }

    if (ret.slice(-2) === ', ') ret = ret.slice(0, -2)

    return ret
  }
}
