export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    type: 'checkbox',
    thClass: 'align-middle text-center text-uupercase',
  },
  // {
  //   key: 'agency',
  //   label: 'Agency',
  //   sortable: false,
  //   class: 'text-center align-middle text-uupercase',
  //   show: true,
  //   thClass: 'align-middle text-center text-uupercase',
  // },
  // {
  //   key: 'advertiser',
  //   label: 'Advertiser',
  //   sortable: false,
  //   class: 'text-center align-middle text-uupercase',
  //   show: true,
  //   thClass: 'align-middle text-center text-uupercase',
  // },
  {
    key: 'client',
    label: 'Client',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'due_at',
    label: 'Due at',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'type',
    label: 'Type',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: false,
  },
  {
    key: 'group_by',
    label: 'Invoice Group',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'details_group',
    label: 'Invoice Details',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'group_by_name',
    label: 'Line Item Name',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'item_count',
    label: 'Number of Invoices',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    type: 'number',
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'gross_rate',
    label: 'Gross Rate',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'net_rate',
    label: 'Net Rate',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'media_plans_gross_total',
    label: 'Media Plans Gross Total',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'media_plans_net_total',
    label: 'Media Plans Net Total',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'gross_total',
    label: 'Gross Total',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'net_total',
    label: 'Net Total',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center text-uupercase',
  },
  {
    key: 'action',
    label: 'Actions',
    sortable: false,
    class: 'text-center align-middle text-uupercase',
    show: true,
    thClass: 'align-middle text-center text-uupercase',
  },
]
