export default [
  {
    key: 'date',
    label: 'Date',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
    type: 'date',
  },
  {
    key: 'day',
    label: 'Day',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'time',
    label: 'Time',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'type',
    label: 'Type',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'cis',
    label: 'CIs',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'pb',
    label: 'P/B',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'mg_for',
    label: 'M/G For',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'film',
    label: 'Film',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'rate',
    label: 'Rate',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
  },

]
