
import { Component, Vue, Prop } from 'vue-property-decorator'
import MediaPlan from '@/models/MediaPlan'
import moment from 'moment'
import MediaPlanItem from '@/models/MediaPlanItem'
import dmas from '@/data/dmas'
import MediaPlanHeaderProperty from './MediaPlanHeaderProperty.vue'

@Component({
  components: {
    MediaPlanHeaderProperty,
  },
})
export default class MediaPlanScheduleView extends Vue {
  @Prop()
  public media_plan!: MediaPlan

  private sortBy = 'start_at'

  private sortDesc = false

  public loading = false

  @Prop({ default: () => [] })
  public showOnly!: string[]

  @Prop({ default: () => [] })
  public highlightItems!: string[]

  @Prop({ default: () => [] })
  public dangerItems!: string[]

  public fields = [
    {
      key: 'code',
      label: 'Code',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'start_at',
      label: 'Start Date',
      sortable: true,
    },
    {
      key: 'end_at',
      label: 'End Date',
      sortable: true,
    },
    {
      key: 'impressions',
      label: 'Impressions',
      sortable: true,
      formatter: (value: number) => value.toLocaleString(),
    },
    {
      key: 'net_rate',
      label: 'Net Rate',
      sortable: true,
      formatter: (value: number) => `$ ${value.toFixed(2)}`,
    },
    {
      key: 'gross_rate',
      label: 'Gross Rate',
      sortable: true,
      formatter: (value: number) => `$ ${value.toFixed(2)}`,
    },
    {
      key: 'net_cost',
      label: 'Net Cost',
      sortable: true,
      formatter: (value: number) => `$ ${value.toFixed(2)}`,
    },
    {
      key: 'gross_cost',
      label: 'Gross Cost',
      sortable: true,
      formatter: (value: number) => `$ ${value.toFixed(2)}`,
    },
    {
      key: 'notes',
      label: 'Notes',
      sortable: true,
    },
  ]

  private schedule: any = []

  public updateSchedule() {
    this.schedule = this.media_plan.formSchedule
  }

  private lineItemSpotIndex(start_at: string, index: number): number {
    let pointer = moment(this.media_plan.start_at).startOf('week').add(index, 'weeks').add(1, 'day')
    let start = moment(start_at).startOf('week').add(1, 'day')

    if (index > 0) {
      start.add(index, 'weeks').startOf('week').add(1, 'day')
    }

    let ret: number = 1 + index

    while (pointer.isBefore(start)) {
      pointer.add(1, 'weeks')
      ret++
    }

    return ret
  }

  private getWeekDates(start_at: string, week: number): any {
    const start = moment(start_at).add(week, 'weeks')
    return {
      start_at: start,
      end_at: start.clone().endOf('week').add(1, 'days'),
    }
  }

  public shouldHightlight(id: string) {
    return this.highlightItems.includes(id)
  }

  public shouldShow(id: string) {
    return this.showOnly.includes(id)
  }

  public shouldShowDanger(id: string) {
    return this.dangerItems.includes(id)
  }

  public rowClass(item: any, type: string) {
    if (!item || type !== 'row') return
    if (this.shouldShowDanger(item.id)) return 'text-danger'
    if (this.shouldHightlight(item.id)) return 'font-weight-bold'
  }

  public get filteredItems() {
    if (this.showOnly.length == 0) return this.media_plan.line_items
    return this.media_plan.line_items.filter((item: any) => this.shouldShow(item.id))
  }

  public getTargeting(line_item: MediaPlanItem, type: string) {
    const include = line_item.metadata.targetting.include[type]
    const exclude = line_item.metadata.targetting.exclude[type]
    const limit = 5

    let ret = ''

    if (include.length + exclude.length === 0) {
      ret = '-'
    } else if (include.length + exclude.length <= limit) {
      if (include.length > 0) {
        ret += include
          .map((i: any) => {
            if (type === 'dmas') {
              return `<span class="text-success"><b>+</b> ${
                dmas.find(d => d.id === i)?.name
              } (${i})</span>`
            }
            return `<span class="text-success"><b>+</b> ${i}</span>`
          })
          .join(', ')
      }

      if (ret && exclude.length > 0) ret += ', '
      if (exclude.length > 0) {
        ret += exclude
          .map((i: any) => {
            if (type === 'dmas') {
              return `<span class="text-danger"><b>-</b> ${
                dmas.find(d => d.id === i)?.name
              } (${i})</span>`
            }
            return `<span class="text-danger"><b>-</b> ${i}</span>`
          })
          .join(', ')
      }
    } else {
      if (include.length > 0) ret += `${include.length} included`

      if (ret && exclude.length > 0) ret += ', '

      if (exclude.length > 0) ret += `${exclude.length} excluded`
    }

    if (ret.slice(-2) === ', ') ret = ret.slice(0, -2)

    return ret
  }
}
