
import { Component, Prop, Watch } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import MediaPlan from '@/models/MediaPlan'
import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import PdfModal from '@/components/PdfPreview/PdfModal.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import Preview from '@/components/PdfPreview//Preview.vue'
import SideNavigator from '@/components/PdfPreview/SideNavigator.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import Api from '@/models/Api'
import ModelFileUploader from '@/components/ModelFile/ModelFileUploader.vue'
import MediaPlanPrintView from './components/MediaPlanPrintView.vue'
import MediaPlanScheduleView from './components/MediaPlanScheduleView.vue'

@Component({
  components: {
    Widget,
    MediaPlanPrintView,
    MediaPlanScheduleView,
    PdfModal,
    IconAction,
    Preview,
    SideNavigator,
    SelectPicker,
    ModelFileUploader,
  },
})
export default class MediaPlanView extends ViewModel {
  @Prop()
  public id!: string

  @Prop({ default: false })
  public noNavigation!: boolean

  @Prop({ default: false })
  public showDelivery!: boolean

  @Prop({ default: 'plan' })
  public mode!: string

  @Prop({ default: () => [] })
  public showOnly!: string[]

  @Prop({ default: () => [] })
  public highlightItems!: string[]

  @Prop({ default: () => [] })
  public dangerItems!: string[]

  public view_mode = 'media_plan'

  private ref: string = 'MediaPlans'

  public tabIndex: number = 0

  public busy = true

  public media_plan: MediaPlan | null = null

  public local_mode: string = 'plan'

  public previewing_type: any = null

  public get pdf_file_path() {
    if (this.tabIndex === 1) {
      return `export-download?target=repository::media_plan/${this.media_plan?.id}/${this.names.trade_io}`
    }

    return `export-download?target=repository::media_plan/${this.media_plan?.id}/${this.names.cash_io}`
  }

  public get pdf_name() {
    let name = 'Client Insertion Order'

    if (this.tabIndex === 1) {
      name = 'Client Insertion Order - Trade'
    } else if (this.media_plan?.isLinear) {
      name = 'Client Insertion Order - Cash'
    }

    return name
  }

  public downloadDocument() {
    new Api().download(
      this.pdf_file_path,
      `#${this.media_plan?.number} - ${this.media_plan?.name} - ${this.pdf_name}.pdf`,
    )
  }

  public preview_pdf: boolean = false

  @Prop({ default: () => null })
  public showDetails!: boolean

  private internalShowDetails: boolean = false

  public get localShowDetails() {
    return this.showDetails === null ? this.internalShowDetails : this.showDetails
  }

  public set localShowDetails(value: boolean) {
    if (this.showDetails === null) {
      this.internalShowDetails = value
      return
    }
    this.$emit('update:showDetails', value)
  }

  @Watch('localShowDetails')
  public onShowDetailsChange(value: boolean) {
    this.media_plan?.toggleAllDetails(value)
  }

  @Watch('id')
  public onIdChange(value: string) {
    if (this.media_plan && this.media_plan.id === value) {
      return
    }
    this.busy = true
    MediaPlan.find(this.id, { include_details: true }).then(o => {
      if (o instanceof MediaPlan) {
        this.media_plan = o
      } else {
        this.$router.push({ name: this.ref })
        return
      }

      this.media_plan?.toggleAllDetails(this.localShowDetails)
      this.busy = false
    })
  }

  public pdf_size = 1

  public pdf_original_width: number = 0

  public names: any = {
    cash_io: 'cash_io.pdf',
    trade_io: 'trade_io.pdf',
  }

  public get file_name_list() {
    return [
      {
        name:
          this.media_plan && this.media_plan.isLinear
            ? 'Client Insertion Order - Cash'
            : 'Client Insertion Order',
        value: 'cash_io',
      },
      {
        name: 'Client Insertion Order - Trade',
        value: 'trade_io',
      },
    ]
  }

  public setViewMode(mode: string) {
    if (
      mode !== 'media_plan'
      && !this.media_plan?.metadata.cash_io
      && !this.media_plan?.metadata.trade_io
    ) {
      WebMessage.error('No files to preview, please contact Account Manager')
      return
    }
    this.pdf_size = 1
    this.view_mode = mode
  }

  @Watch('tabIndex')
  public onTabIndexChange(value: number) {
    this.pdf_size = 1
    this.pdf_original_width = 0
  }

  public previewPdf(type: string) {
    if (!this.media_plan) return

    this.view_mode = 'media_plan'
    this.previewing_type = type
    if (type === 'cash_io') {
      this.tabIndex = 0
    } else {
      this.tabIndex = 1
    }
    this.preview_pdf = true
  }

  @Watch('pdf_size')
  public zoomPdf(value: number, old: number) {
    let pdf_body: any = document.getElementsByClassName('pdf-body')
    pdf_body = pdf_body[0]

    if (this.pdf_original_width === 0) {
      this.pdf_original_width = pdf_body.clientWidth
    }

    let per_size = this.pdf_original_width / 8
    if (this.pdf_size === 1) {
      pdf_body.style.width = '100%'
    } else {
      pdf_body.style.width = `${this.pdf_original_width + per_size * this.pdf_size}px`
    }
  }

  public download() {
    if (this.media_plan) {
      this.media_plan
        .export('io', {
          io_files: ['system'],
        })
        .then(() => {
          WebMessage.success('Generating IO, do not close this window!')
        })
    }
  }

  public editMediaPlan() {
    if (this.media_plan) this.$router.push(`/app/sales/media_plan/${this.id}`)
  }

  public back() {
    this.$router.push({ name: this.ref })
  }

  public created() {
    const { query } = this.$route

    if (query.ref && typeof query.ref === 'string') {
      this.ref = query.ref
    }

    if (!this.id) {
      this.$router.push({ name: this.ref })
      return
    }

    this.local_mode = this.mode

    MediaPlan.find(this.id, { include_details: true }).then(o => {
      if (o instanceof MediaPlan) {
        this.media_plan = o
      } else {
        this.$router.push({ name: this.ref })
        return
      }

      this.media_plan?.toggleAllDetails(this.localShowDetails)
      this.busy = false
    })
  }

  @Watch('mode')
  public onModeChanged(val: string) {
    this.local_mode = val
  }
}
