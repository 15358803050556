
import { Component, Vue, Prop } from 'vue-property-decorator'
import ModelFileUploader from '@/components/ModelFile/ModelFileUploader.vue'
import ViewModel from '@/models/ViewModel'
import DataTable from '@/components/DataTable/index.vue'
import InvoiceItem from '@/models/InvoiceItem'
import invoice_printview_table_fields from '../invoice-printview-table-fields'

@Component({
  components: {
    ModelFileUploader,
    DataTable,
  },
})
export default class InvoicePrintView extends ViewModel {
  @Prop()
  public invoice!: any

  @Prop({ default: () => [] })
  public errors!: string[]

  @Prop({ default: () => [] })
  public warnings!: string[]

  // build
  @Prop({ default: false })
  public hide_file_attachment!: any

  @Prop({ default: false })
  public useDefaultView!: boolean

  public get reviewItems() {
    return [...this.invoice.items]
  }

  public get isMediaOcean() {
    return this.invoice.type === 'media_ocean'
  }

  public get show_gross() {
    return this.invoice.items.reduce(
      (acc: boolean, item: InvoiceItem) => acc || item.gross_rate - item.net_rate > 0.05,
      false,
    )
  }

  public get invoice_printview_fields() {
    let fields = invoice_printview_table_fields.map(f => {
      if (f.key.includes('gross') && !this.show_gross) {
        f.show = false
      } else if (f.key.includes('net') && !this.show_gross) {
        f.label = f.label.replaceAll('Net ', '')
      }
      return f
    })

    const updateLabel = (key_name: string, update_with: string) => {
      let index = fields.findIndex(f => f.key === key_name)
      if (index >= 0) fields[index].label = update_with
    }

    const showField = (key_name: string, value: boolean) => {
      let index = fields.findIndex(f => f.key === key_name)
      if (index >= 0) fields[index].show = value
    }

    if (this.invoice_view === 'discount') {
      updateLabel('gross_rate', 'Pre-Discount Rate')
      updateLabel('gross_total', 'Pre-Discount Total')
      updateLabel('net_rate', 'Discount Rate')
      updateLabel('net_total', 'Discount Total ')
    }

    if (this.invoice_view === 'no_flight') {
      fields = fields.filter(f => f.key !== 'flight_dates')
    }

    if (this.invoice_view === 'totals') {
      let allowed = ['index', 'name', 'gross_total', 'net_total']
      fields = fields.filter(f => allowed.includes(f.key))
    }

    if (this.invoice_view === 'beachfront') {
      let allowed = ['index', 'name', 'gross_total']
      fields = fields.filter(f => allowed.includes(f.key))
    }

    if (this.invoice_view === 'third_party' || this.invoice_view === 'third_party_isci') {
      showField('booked', true)
      showField('system_impressions', false)
      showField('reconciliated_impressions', true)

      if (this.invoice_view === 'third_party_isci') {
        showField('isci_code', true)
      }
    }

    if (this.invoice_view === 'totals_service_month') {
      let allowed = ['index', 'name', 'gross_total', 'net_total', 'service_month']
      fields = fields.filter(f => allowed.includes(f.key))
      showField('service_month', true)
    }

    if (this.invoice_view === 'service_month') {
      showField('service_month', true)
      showField('flight_dates', false)
    }

    if (this.invoice_view === 'buffalo_rock_agency') {
      showField('service_month', true)
      showField('isci_code', true)
      showField('isci_duration', true)
    }

    return fields.filter(f => f.show)
  }

  public errorClass(...targets: string[]): string {
    for (const target of targets) {
      if (this.errors.includes(target)) {
        return 'text-danger font-weight-bolder'
      }

      if (this.warnings.includes(target)) {
        return 'text-warning font-weight-bolder'
      }
    }

    return ''
  }

  public clearName(name: string) {
    return name.replaceAll('_', ' ')
  }

  public itemList(media_plan_id: any) {
    return this.invoice.items.filter((e: any) => this.getMediaPlanItems(media_plan_id, e.name))
  }

  public getMediaPlanItems(media_plan_id: string, name: string) {
    let splited = name.split(':')
    let plan = splited[0]
    let found = this.invoice.media_plans.find(mp => mp.id === media_plan_id)
    return found.name === plan
  }

  public get invoice_view() {
    return this.useDefaultView ? 'default' : this.invoice.template_id
  }
}
